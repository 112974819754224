@import 'styles/vars';

.card {
    border-radius: 4px;
    box-shadow: 
        0 1px 1px 0 rgba(60,64,67,.08),
        0 1px 3px 1px rgba(60,64,67,.16)
    ;
    padding: 1rem;
    margin: 1rem 0 2rem 0;
    font-size: 1.4rem;
    overflow: hidden;
    text-decoration: none;
    color: $primary-black;
    display: block;
    appearance: none;
    outline: none;
    background: none;
    border: none;
    width: 100%;
}

.card--vehicle {
    // display: grid;
    // grid-template-columns: 110px auto;
    padding: 0px;
}

.card__label {
    position: absolute;
    z-index: 2;
    right: -.5rem;
    top: -.5rem;
    .label {
        box-shadow: 
            0 1px 1px 0 rgba(60,64,67,.28),
            0 1px 3px 1px rgba(60,64,67,.26)
        ;
    }
}

.card__image {

    // height: 100%;
    height: 0;
    padding-bottom: 50%;
    background: #eee;
    position: relative;
    text-align: center;
    overflow: hidden;
    i {
        // height: 100%;
        font-size: 3.6rem;
        line-height: 8rem;
        color: rgba(0,0,0,0.4);
    }

    .cardImage_overlay {
        position: absolute;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(90deg, rgba(0, 67, 146, 0.6), rgba(0, 67, 146, 0.8));

        .appointment__vehicle {
            color: #fff;
            font-size: 2.2rem;
            font-weight: normal;
        }

        .appointment__dateString {
            color: #fff;
            font-size: 1.8rem;
            margin-top: 2rem;
        }
    }

}

.card__text {

    padding: 2rem;

    h2 {
        font-size: 1.6rem;
        line-height: 4rem;
    }

}

.timeline {

    width: 4px;
    height: 20px;
    margin: -2rem 3.2rem -1rem 3.2rem;
    background: rgba(0,0,0,0.1);

}

.timelineYear {

    margin-left: 3.2rem;

    .timelineYearLine {
        background: rgba(0,0,0,0.1);
        width: 20px;
        height: 4px;
        display: inline-block;
        vertical-align: middle;
        // margin-top: -.1rem;
    }

    .year {
        display: inline-block;
        background: rgba(0,0,0,0.1);
        padding: 0 .8rem;
        line-height: 2.4rem;
        border-radius: 5px;
        font-weight: bold;
        letter-spacing: 0.02em;
        color: rgba(0,0,0,0.6);
        vertical-align: middle;
    }

}

.timeline--past,
.timelineYear--past .timelineYearLine,
.timelineYear--past .year {
    background-color: lighten($success-green, 30);
    color: darken($success-green, 20);
}

.timeline--present,
.timelineYear--present .timelineYearLine,
.timelineYear--present .year {
    background-color: lighten($secondary-blue, 40);
    color: darken($secondary-blue, 10);
}

.cardDate {
    border-radius: 4px 0 0 4px;
    background: #fafafa;
    // margin: -2rem 1rem -2rem -2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.08em;
    font-weight: bold;
    font-size: 1.1rem;
    line-height: 2rem;
    text-transform: uppercase;
    color: rgba(0,0,0,0.75);
    div.day {
        font-size: 1.4rem;
    }
}

.cardDate--past {
    background-color: $success-green;
    color: lighten($success-green, 55);
}

.cardDate--present {
    background-color: $secondary-blue;
    color: lighten($secondary-blue, 55);
}