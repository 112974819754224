@import 'styles/vars';

.noData {
    text-align: center;
    border: 2px dashed rgba(0,0,0,0.2);
    border-radius: 10px;
    font-size: 2rem;
    position: absolute;
    left: 2rem;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
    padding: 2rem;
}

.noData__button {

    outline: none;
    appearance: none;
    display: block;
    margin: 0 auto;
    border: none;
    background: $primary-yellow;
    margin-top: 2rem;
    padding: 1rem 4rem;
    border-radius: 4px;
    font-size: 2rem;
    color: darken($primary-yellow, 40);

}