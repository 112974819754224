.issue__file {
    display: inline-block;
    height: 60px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 1rem;
    box-shadow: 
        0 1px 1px 0 rgba(60,64,67,.08),
        0 1px 3px 1px rgba(60,64,67,.16)
    ;
    margin-bottom: 2rem;
}

h1, h2 {
    .labelPill {
        float: right;
    }
}

.issue__text {
    padding: 1rem 0;
}

.estimate__subtotal,
.estimate__tax,
.estimate__total {
    text-align: right;
}

.estimate__total {
    font-size: 2.6rem;
    margin-top: 1rem;
}