.loader {
    background: #eee;
    border-radius: 4px;
    animation: FlashBackground 1s ease infinite;
    background: linear-gradient(100deg, #ddd 25%, #efefef 50%, #ddd 75%);
    background-size: 400% 400%;
    margin-bottom: 2rem;
}

.loader.loader--card {
    height: 110px;
}

.loader.loader--heading {
    height: 21px;
    border-radius: 10px;
    margin: 14.94px 0 !important;
}

.loader.loader--text {
    height: 14px;
    border-radius: 7px;
}

@keyframes FlashBackground {
    0% { background-position-x: 100%; }
    100% { background-position-x: 0%; }
}