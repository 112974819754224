@import 'vars';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

html {
  font-size: 62.5%; 
}

root, html, body, #root {
    height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: helvetica, arial, verdana, sans-serif;
  font-size: 1.6rem;
  background: $primary-blue;
  color: $primary-black;
  overflow: hidden;
}

.container {
  background: #fafafa;
  height: 100%;
}

.container--main {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.container--login {
  text-align: center;
  // background: $primary-blue;
  color: $primary-white;

  .logo {
    margin: 4rem 0;
    color: $primary-blue;
    font-size: 96px;
    margin-top: 0;
    width: 100%;
  }

}

.containerPadding {
  padding: 2rem;
  overflow-y: auto;
}

.constrain {
  max-width: 960px;
  margin: 0 auto;
}

h1, h2, h3, h4, h5, h6 {
  color: $primary-blue;
  letter-spacing: 0.02em;
  font-weight: normal;
}

h1 {
  font-size: 2.2rem;
  margin-top: 0;
}

h2 {
  font-size: 1.9rem;
  border-bottom: 1px solid #eee;
  padding: 1rem 0 1rem 0;
  margin: 0;
}

.StripeElement {
  border-radius: 4px;
  padding: 1rem 1.5rem;
  border: 1px solid rgba(0,0,0,0.15);
  font-family: helvetica, arial, verdana, sans-serif;
}

.anchor.normal.light {
  border-radius: 4px !important;
}

.languageSwitcher {
  outline: none;
  appearance: none;
  display: block;
  margin: 0 auto;
  border: none;
  background: none;
  cursor: pointer;
  color: lighten($primary-blue, 20);
  padding: 1rem;
}