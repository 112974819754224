.imageBox__container { 
    margin: 0 4rem;
    position: relative;
    margin-bottom: 4rem;
}

.imageBox__image {
    box-shadow: 
        0 1px 1px 0 rgba(60,64,67,.08),
        0 1px 3px 1px rgba(60,64,67,.16)
    ;
    border-radius: 4px;
    overflow: hidden;
}

.imageBox__progressContainer {

    text-align: center;
    position: absolute;
    bottom: -2.5rem;
    left: 0;
    right: 0;

    .imageBox__progress {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #c4c4c4;
        display: inline-block;
    }

    .imageBox__progress--active {
        background: #696969;
    }

}