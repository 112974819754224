.appointmentSection {
    // position: relative;


    .appointmentSection__expanderButton {

        width: 100%;
        outline: none;
        appearance: none;
        padding: 1rem;
        margin: 0;
        background: none;
        border: none;
        text-align: left;
        position: relative;
        // position: absolute;
        // right: 0;
        div {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            i {
                transform: rotate(0deg);
                transition: all 250ms;
            }
        }
    }

    .appointmentSection__expanderButton.appointmentSection__expanderButton--open {
        i {
            transform: rotate(90deg);
            transition: all 250ms;
        }
    }

    .appointmentSection__body {
        overflow: hidden;
    }

    .appointmentSection__body.appointmentSection__body--closed {
        max-height: 0;
    }

    .appointmentSection__body.appointmentSection__body--open {
        max-height: 10000px;
    }

}