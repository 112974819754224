@import 'styles/vars';

.appointment__container {
    text-align: center;

    .appointment__vehicle {
        font-size: 1.6rem;
        margin-bottom: 1rem;
        color: $primary-blue;
    }

    .appointment__date {

        .appointment__dateString {
            font-size: 1.8rem;
        }

        .appointment__dateTime {
            font-size: 1.2rem;
        }

    }

    .appointment__installer {
        margin-top: 1rem;
    }

}