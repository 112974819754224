@import 'styles/vars';

.iconLink {
    text-align: center;
    text-decoration: none;
    margin: 2rem 2rem 0 2rem;
    color: $primary-blue;
    text-transform: uppercase;
    font-size: 1.1rem;
    i {
        display: block;
        margin-bottom: 1rem;
    }
}

.iconLink--small {
    margin: 0 0 0 2rem;
    i {
        margin: 0;
    }
}

.iconLink--medium {
    font-size: 1rem;
}