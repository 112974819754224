@import 'styles/vars';

.installerFooter__container {

    background: $primary-blue;
    box-shadow: 0 -3px 4px rgba(0,0,0,0.2);
    max-height: 40px;
    text-align: center;
    position: relative;
    transition: all 250ms;
    padding: 0 2rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    color: $primary-white;

    .installerFooter__expanderButton {
        // position: absolute;
        // right: 0;
        height: 40px;
        // width: 40px;
        padding: 0;
        width: 100%;
        outline: none;
        appearance: none;
        border: none;
        background: none;
        cursor: pointer;
        position: relative;
        color: $primary-white;
        div {
            top: 0;
            height: 40px;
            width: 40px;
            position: absolute;
            right: -20px;
        }
        i {
            transition: all 250ms;
            transform: rotate(0deg);
            color: $primary-white;
            line-height: 40px;
        }
    }

    .installerFooter__expanderButton:hover {

        i {
            color: rgba(255,255,255,0.6);
        }

    }

    .installerFooter__businessName {

        padding: 1rem 0;

    }

    .installerFooter__address {
        padding: 2rem 0 1rem 0;
        color: lighten($primary-blue, 55);
    }

    .installerFooter__contact {
        display: flex;
        justify-content: space-evenly;
        padding-bottom: 2rem;

        .iconLink {
            color: lighten($primary-blue, 55);
        }

    }

}

.installerFooter__container.installerFooter__container--open {
    max-height: 500px;
    transition: all 250ms;

    .installerFooter__expanderButton {
        i {
            transform: rotate(180deg);
            transition: all 250ms;
        }
    }

}