@import 'styles/vars';

.accessCodeContainer {

    .accessCodeHelperText {
        color: rgba(0,0,0,0.75);
        margin-bottom: 1rem;
    }

    .accessCodeParts {
        display: flex;
        justify-content: center;

        .accessCode__input {
            width: 4rem;
            height: 4rem;
            border-radius: 4px;
            border: 1px solid rgba(0,0,0,0.15);
            text-align: center;
            font-size: 2.8rem;
            line-height: 4rem;
            padding: 0;
            margin: 6px;
            color: $primary-blue;
            outline: none;
        }

        .accessCode__input:focus {
            border: 2px solid $primary-blue;
            margin: 5px;
        }

    }

    
    
}
.pasteButton {
    outline: none;
    appearance: none;
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    color: $primary-blue;
    font-weight: bold;
    margin-top: 4rem;
}
.error {
    color: red;
    background: lighten(red, 45);
    border: 1px solid lighten(red, 20);
    border-radius: 4px;
    padding: 1rem;
    margin-top: 1rem;
}