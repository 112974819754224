$primary-blue: #0d2a8a;
$secondary-blue: lighten($primary-blue, 20);
$primary-white: rgba(255,255,255,0.95);
$light-gray: rgba(0,0,0,0.2);
$read-only-gray: #A7A7A7;
$success-green: #33A532;
$primary-yellow: #F5C211;
$primary-black: rgba(0,0,0,0.90);
$primary-red: #ff0000;
$primary-orange: #FF6347;
$border-radius: 4px;

$sm: 35.5em;
$md: 48em;
$lg: 64em;
$xl: 80em;