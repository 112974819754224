@import 'styles/vars';

.fieldContainer {
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 4px;
    margin-bottom: 1rem;
    position: relative;
    height: 40px;

    .field__label {
        font-size: 1.5rem;
        color: rgba(0,0,0,0.6);
        line-height: 40px;
        position: absolute;
        left: 1.3rem;
        right: 1.3rem;
        transition: all 150ms ease-in-out;
        text-align: left;
    }

    .field__helper {
        font-size: 1.2rem;
        color: rgba(0,0,0,0.4);
        text-align: right;
        position: absolute;
        left: 1.3rem;
        right: 1.3rem;
        line-height: 40px;
        transition: all 150ms ease-in-out;
    }

    input,
    select,
    textarea {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        margin: 0;
        padding: 0;
        line-height: 3rem;
        background: none;
        border: none;
        outline: none;
        appearance: none;
    }

    input {
        text-indent: 1.3rem;
    }
    select {
        padding-left: 1.3rem;
        padding-right: 1.3rem;
    }
    textarea {
        padding: 1.3rem;
        line-height: initial;
        padding-top: 2rem;
        box-sizing: border-box;
        resize: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: none;
        border-radius: 4px;
        -webkit-text-fill-color: #000;
        -webkit-box-shadow: 0 0 0px 1000px rgba(0,0,0,0.0) inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    .checkbox {
        display: inline-block;
        position: absolute;
        left: 1.3rem;
        text-indent: 0px;
        z-index: -1;
        i {
            line-height: 40px;
            color: rgba(0,0,0,0.6);
        }
    }

    .checkbox.checkbox--checked {
        i {
            color: $secondary-blue;
        }
    }

}

.fieldContainer--textarea {
    height: 120px;
}

.fieldContainer--checkbox {
    text-indent: 3.3rem;
    cursor: pointer;
    .field__label {
        color: $primary-black;
    }
}

.fieldContainer--focused:not(.fieldContainer--checkbox),
.fieldContainer--filled:not(.fieldContainer--checkbox) {

    .field__label {
        line-height: 2.2rem;
        font-size: 1.2rem;
        transition: all 100ms ease-in-out;
    }

    .field__helper {
        line-height: 2.2rem;
        font-size: 1rem;
        transition: all 100ms ease-in-out;
    }

    input {
        padding-top: 1.8rem;
    }

    select {
        padding-top: 1.4rem;
    }

}

.fieldContainer--focused {
    border-color: lighten($primary-blue,20);
}

.submitButton {
    background: lighten($primary-blue, 20);
    padding: 1.2rem 3rem;
    outline: none;
    appearance: none;
    display: inline-block;
    border:none;
    border-radius: 4px;
    color: $primary-white;
}

.submitButton:hover {
    background: lighten($primary-blue, 10);
}

.submitButton:disabled {
    color: rgba(255,255,255,0.6);
}

fieldset {

    border: none;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem;

    legend {

      color: $secondary-blue;
      text-align: left;
      font-size: 1.8rem;
      margin: 0;
      margin-bottom: 1rem;
      padding: 0;

    }

  }