@import 'styles/vars';

.button {
    outline: none;
    appearance: none;
    background: #048657;
    border: none;
    padding: 2rem;
    text-align: center;
    border-radius: 4px;
    width: 100%;
    margin: 2rem 0;
    color: $primary-white;
    font-size: 2rem;
    letter-spacing: 0.02em;
    display: block;
    box-sizing: border-box;
    text-decoration: none;
    cursor: pointer;
}

.button:hover {
    background: darken(#048657, 10);
}

.button.decline {
    background: #860404;
}
.button.decline:hover {
    background: darken(#860404, 10);
}